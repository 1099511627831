<script lang="ts" setup>
import { DOMEvent } from '@/types/dom'
import { passInputValidation } from '@/app/utils/helper'

const props = defineProps({
  border: Boolean,
  disabled: Boolean,
  lg: Boolean,
  md: Boolean,
  showSearch: Boolean,

  inputClasses: {
    type: String,
    default: ''
  },

  wrapperClasses: {
    type: String,
    default: ''
  }
})

const emit = defineEmits<{
  (e: 'enter'): void
  (e: 'keydown', event: DOMEvent<HTMLInputElement>): void
}>()

const iconSearchClasses = computed(() => {
  if (props.lg) {
    return 'w-6 h-6 text-gray-600'
  }

  if (props.md) {
    return 'w-4 h-4 text-gray-700'
  }

  return 'w-3 h-3 text-gray-700'
})

const inputClasses = computed(() => {
  return {
    'input-md': props.md,
    'leading-4': !props.lg,
    'input-lg leading-9 p-y-2': props.lg,
    'pl-5': props.showSearch && !props.lg,
    'pl-10': props.showSearch && props.lg,
    [props.inputClasses]: !!props.inputClasses
  }
})

function handleKeydownEvent(event: any) {
  const passedInputValidation = passInputValidation(event)

  if (!passedInputValidation) {
    event.preventDefault()
  } else {
    emit('keydown', event)
  }
}
function handleEnterEvent() {
  emit('enter')
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<template>
  <div class="w-full">
    <div
      class="flex justify-between"
      :class="[wrapperClasses, lg ? 'leading-9' : 'leading-4']"
    >
      <div class="flex items-center">
        <slot name="prefix">
          <BaseIcon
            v-if="showSearch"
            name="search"
            class="cursor-pointer"
            :class="iconSearchClasses"
            @click="handleEnterEvent"
          />
        </slot>
      </div>

      <BaseInput
        class="input w-full"
        :class="inputClasses"
        v-bind="$attrs"
        @keyup.enter="handleEnterEvent"
        @keydown="handleKeydownEvent"
      />

      <div v-if="$slots.addon">
        <slot name="addon" />
      </div>
    </div>
  </div>
</template>
